import React from 'react';
import style from './Heading.module.css';

const Heading = ({ level, size, align, children }) => {
  const HeadingTag = `h${level}`;

  const sizeClass = () => {
    switch (size) {
      case 'small':
        return style.small;
      case 'medium':
        return style.medium;
      default:
        return 'medium';
    }
  };

  const alignClass = () => {
    switch (align) {
      case 'center':
        return style.center;
      case 'left':
        return style.left;
      default:
        return style.center;
    }
  };

  return <HeadingTag className={`${style.heading} ${sizeClass()} ${alignClass()}`}>{children}</HeadingTag>;
};

export default Heading;
