import React from 'react';
import style from './List.module.css';

const List = ({ children, ...props }) => {
  const List = props.type === 'ordered' ? 'ol' : 'ul';
  const listStyle = props.type === 'ordered' ? style.ordered : style.unordered;

  return <List className={`${style.list} ${listStyle}`}>{children}</List>;
};

export default List;
