import React from 'react';
import Brand from '../Brand/Brand';
import Button from '../Button/Button';
import style from './Header.module.css';

const Header = () => {
  return (
    <div className={style.header}>
      <div className={style.wrapper}>
        <Brand />
        <div className={style.content}>
          <p className={style.title}>O maior evento para a Geração Prateada do Brasil</p>
          <Button href="https://longevidade.com.br/">Sobre o evento</Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
