import React from 'react';
import style from './Button.module.css';

const Button = ({ children, ...props }) => {
  return (
    <a className={style.button} {...props}>{children}</a>
  );
};

export default Button;
