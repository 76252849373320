import React from 'react';
import style from './Partners.module.css';
import ativen from '../../assets/ativen.png';
import seniorLab from '../../assets/seniorlab.png';

const Partners = () => {
  return (
    <div className={style.partners}>
      <div className={style.wrapper}>
        <a href="https://ativen.com.br/" title="Site Ativen" target="_blank" rel="noopener noreferrer">
          <img src={ativen} alt="Ativen Evenlhecimento Ativo" width="133" height="52" loading="lazy" />
        </a>
        <a href="https://seniorlab.com.br/" title="Site SeniorLab" target="_blank" rel="noopener noreferrer">
          <img src={seniorLab} alt="SeniorLab Mercado &amp; consuymo 60+" width="133" height="99" loading="lazy" />
        </a>
      </div>
    </div>
  );
};

export default Partners;
