import React from 'react';
import CounterWrapper from '../../components/CounterWrapper/CounterWrapper';
import CurrentDateTime from '../../components/CurrentDateTime/CurrentDateTime';
import Heading from '../../components/Heading/Heading';
import Stack from '../../components/Stack/Stack';
import SubpageFrame from '../../components/SubpageFrame/SubpageFrame';

import dataOlderThan50 from '../../data/older-than-50.json';

const Populacao50 = () => {
  const older50Total = dataOlderThan50.filter((item) => item.type === 'total_count');

  return (
    <SubpageFrame>
      <Stack>
        <Heading level="2" size="medium">Contador População 50+ Brasil</Heading>
        <CounterWrapper data={older50Total} counterSize="large" />
        <CurrentDateTime />
      </Stack>
    </SubpageFrame>
  );
};

export default Populacao50;
