import React from 'react';
import style from './Details.module.css';

const Details = ({ summaryContent, children }) => {
  return (
    <details className={style.details}>
      <summary className={style.summary}>{summaryContent}</summary>
      <div className={style.content}>{children}</div>
    </details>
  );
};

export default Details;
