import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './pages/Home/Home';
import Populacao50 from './pages/Populacao50/Populacao50';
import Populacao60 from './pages/Populacao60/Populacao60';

const App = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/populacao-50" element={<Populacao50 />} />
      <Route path="/populacao-60" element={<Populacao60 />} />
    </Routes>
  );
};

export default App;
