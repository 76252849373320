import React from 'react';
import Layout from '../../components/Layout/Layout';
import Main from '../../components/Main/Main';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Content from '../../components/Content/Content';
import Stack from '../../components/Stack/Stack';
import Heading from '../../components/Heading/Heading';
import Divider from '../../components/Divider/Divider';
import Details from '../../components/Details/Details';
import List from '../../components/List/List';
import Partners from '../../components/Partners/Partners';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import CurrentDateTime from '../../components/CurrentDateTime/CurrentDateTime';
import CounterWrapper from '../../components/CounterWrapper/CounterWrapper';
import ScriptCopy from '../../components/ScriptCopy/ScriptCopy';
import dataOlderThan50 from '../../data/older-than-50.json';
import dataOlderThan60 from '../../data/older-than-60.json';

export default function Home() {
  const older60Total = dataOlderThan60.filter((item) => item.type === 'total_count');
  const older50Total = dataOlderThan50.filter((item) => item.type === 'total_count');
  const older50States = dataOlderThan50.filter((item) => item.type === 'by_state');

  return (
    <>
      <Layout>
        <Header />
        <Main>
          <VisuallyHidden>
            <Heading level="1">Contador de População +50 e +60 no Brasil</Heading>
          </VisuallyHidden>
          <Stack>
            <Heading level="2" size="medium">Contador População 50+ Brasil</Heading>
            <CounterWrapper data={older50Total} counterSize="large" />
          </Stack>
          <CurrentDateTime />
          <Partners />
          <Divider />
          <Stack>
            <Heading level="2" size="medium">Contador População 50+ Brasil por UF</Heading>
            <CounterWrapper data={older50States} headingLevel="3" headingSize="small" counterSize="medium" />
          </Stack>
          <Divider />
          <Stack>
            <Heading level="2" size="medium">Contador População 60+ Brasil</Heading>
            <CounterWrapper data={older60Total} counterSize="large" />
          </Stack>
          <Divider />
          <Content>
            <Heading level="2" size="medium">Publique nosso contador no seu site!</Heading>
            <p>Publique o mini painel <strong>Contador População 50+ ou 60+</strong> no seu site e ajude a dar visibilidade a esta população e seu potencial. Basta copiar e colar na sua página.</p>
            <Heading level="3" size="small" align="left">Script do Contador 50+</Heading>
            <ScriptCopy age="50"/>
            <Heading level="3" size="small" align="left">Script do Contador 60+</Heading>
            <ScriptCopy age="60"/>
          </Content>
          <Divider />
          <Content>
            <Heading level="2" size="medium">Fontes, parâmetros e nota técnica</Heading>
            <p>Este exercício da SeniorLab mercado &amp; consumo 60+ com base em dados e projeções do IBGE tem o objetivo de tangibilizar o crescimento destes extratos populacionais a fim de aumentar a percepção de mercado a respeito dos seus potenciais. O número estimado para cada faixa da população na data, hora, minuto e segundo é uma projeção da SeniorLab mercado & consumo 60+ tendo como fonte dados do IBGE com a projeção da população Brasil e Unidades da Federação por idade até 2060.</p>
            <p>A SeniorLab identificou e utiliza a razão de crescimento da população do grupo Brasil 50+ e 60+ assim como o dos grupos 50+ por unidades federativas.</p>
            <Details summaryContent="Critérios do cálculo de crescimento"> 
              <Content>
                <List type="unordered">
                  {older60Total.map((item, index) => (
                    <li key={index}>{item.label} cresce a cada {item.time_to_increment_by_seconds} segundos.</li>
                  ))}
                  {older50Total.map((item, index) => (
                    <li key={index}>{item.label} cresce a cada {item.time_to_increment_by_seconds} segundos.</li>
                  ))}
                  {older50States.map((item, index) => (
                    <li key={index}>{item.label} 50+ cresce a cada {item.time_to_increment_by_seconds} segundos.</li>
                  ))}
                </List>
                <p>* O contador Brasil 60+ considera o ajuste de correção populacional 60+ em 2017 com base da <a href="https://agenciadenoticias.ibge.gov.br/agencia-noticias/2012-agencia-de-noticias/noticias/20980-numero-de-idosos-cresce-18-em-5-anos-e-ultrapassa-30-milhoes-em-2017">PNAD IBGE</a> divulgada em 01/10/2018 e que soma-se a razão de crescimento anual projetada até 2021.</p>
              </Content>
            </Details>
          </Content>
          <Divider />
        </Main>
      </Layout>
      <Footer />
    </>
  );
};