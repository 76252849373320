import React from 'react';
import style from './Brand.module.css';
import brandPositive from '../../assets/logo-longevidade.svg';
import brandMonochrome from '../../assets/logo-longevidade-mono.svg';

const Brand = ({ variant }) => {
  let brand;

  switch (variant) {
    case 'positive':
      brand = brandPositive;
      break;
    case 'monochrome':
      brand = brandMonochrome;
      break;
    default:
      brand = brandPositive;
  }

  return (
    <a className={style.brand} href="https://longevidade.com.br/" title="Site Longevidade">
      <img src={brand} alt="Longevidade" width="256" height="72" loading="lazy" />
    </a>
  );
};

export default Brand;
