import React, { useState, useEffect } from 'react';
import style from './Counter.module.css';

const Counter = ({ size, data }) => {
  const [count, setCount] = useState('000000'); //

  const { initial_count, time_to_increment_by_seconds } = data;

  // Add each count digit to array. It's needed to add a span element to each digit.
  const countDigits = count.toString().split('');

  useEffect(() => {
    // Set initial count.
    const countStartDate = new Date('2022-07-10T00:00:00.000Z');

    const interval = setInterval(() => {
      const now = new Date(); // Get current date.
      const diff = now - countStartDate; // Get difference between current date and start date.
      const seconds = Math.floor(diff / 1000); // Get seconds from difference.
      const increment = Math.floor(seconds / time_to_increment_by_seconds); // Get increment value.
      setCount(initial_count + increment); // Set count.
    }, 1000);

    return () => clearInterval(interval);
  }, [initial_count, time_to_increment_by_seconds]);

  const sizeHandler = () => {
    switch (size) {
      case 'medium':
        return style.medium;
      case 'large':
        return style.large;
      default:
        return style.medium;
    }
  };

  return (
    <div className={`${style.counter} ${sizeHandler()}`}>
      {countDigits.map((digit, index) => (
        <span className={style.number} key={index}>
          {digit}
        </span>
      ))}
    </div>
  );
};

export default Counter;
