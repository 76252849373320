import React from 'react';
import CounterWrapper from '../../components/CounterWrapper/CounterWrapper';
import CurrentDateTime from '../../components/CurrentDateTime/CurrentDateTime';
import Heading from '../../components/Heading/Heading';
import Stack from '../../components/Stack/Stack';
import SubpageFrame from '../../components/SubpageFrame/SubpageFrame';

import dataOlderThan60 from '../../data/older-than-60.json';

const Populacao60 = () => {
  const older60Total = dataOlderThan60.filter((item) => item.type === 'total_count');

  return (
    <SubpageFrame>
      <Stack>
        <Heading level="2" size="medium">Contador População 60+ Brasil</Heading>
        <CounterWrapper data={older60Total} counterSize="large" />
        <CurrentDateTime />
      </Stack>
    </SubpageFrame>
  );
};

export default Populacao60;
