import React from 'react';
import style from './CurrentDateTime.module.css';

const CurrentDateTime = () => {
  const [date, setDate] = React.useState(new Date());

  React.useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const addLeadingZeros = (value) => (value < 10 ? `0${value}` : value);

  const formattedDate = `${addLeadingZeros(date.getDate())}/${addLeadingZeros(date.getMonth() + 1)}/${date.getFullYear()}`;
  const formattedTime = `${addLeadingZeros(date.getHours())}h${addLeadingZeros(date.getMinutes())}`;

  return (
    <div className={style.currentDateTime}>
      <span className={style.label}>Atualizado em:</span>
      <span className={style.date}>{`${formattedDate} às ${formattedTime}`}</span>
    </div>
  );
};

export default CurrentDateTime;
