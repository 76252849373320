import React from 'react';
import Heading from '../Heading/Heading';
import Counter from '../Counter/Counter';
import style from './CounterWrapper.module.css';

const CounterWrapper = ({ data, headingLevel, headingSize, counterSize }) => {
  return (
    <div className={style.counterWrapper}>
      {data.map((dataItem, index) => (
        <div className={style.item} key={index}>
          {dataItem.type !== 'total_count' && (
            <Heading level={headingLevel} size={headingSize}>
              {dataItem.label}
            </Heading>
          )}
          <Counter data={dataItem} size={counterSize} />
        </div>
      ))}
    </div>
  );
};

export default CounterWrapper;
