import React from 'react';
import style from './ScriptCopy.module.css';

const ScriptCopy = ({ age }) => {
  age = age === '' ? '50' : age;

  const selectAllText = (e) => {
    e.target.select();
  };
  return <textarea name="scriptCopy" defaultValue={`<iframe src="//contador.longevidade.com.br/populacao-${age}" height="280" width="100%" style="border:none;"></iframe>`} className={style.scriptCopy} onClick={selectAllText}></textarea>;
};

export default ScriptCopy;
